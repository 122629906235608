<template>
  <div id="app" ref="appRef">
    <el-container class="container">
     <!-- <el-header>
        <Header></Header>
      </el-header> -->
      <el-container>
       <!-- <el-aside>
          <Aside></Aside>
        </el-aside> -->
        <el-container>
          <!-- <el-main> -->
            <keep-alive><router-view/></keep-alive>
          <!-- </el-main> -->
         <!-- <el-footer>
            <Footer></Footer>
          </el-footer> -->
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Aside from '@/components/home/Aside';
export default {
  // mixins: [drawMixin],
  components:{
    Aside,
  }
}
</script>

<style lang="less">
@import './less/media.less';
*{
  margin: 0;
  padding: 0;
}

#app {
  width: 100%;
  height: 100%;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // transform-origin: left top;
  overflow: hidden;
  background: #101D49;
}
.el-header{
  padding: 0;
  min-width: 1200px;
}
.el-aside{
  box-shadow: 1px 0 5px 0 #ededf5;
  transform: translate3d(0, 0, 0);
}
.el-main{
  min-width: 880px;
}
.el-footer{
  height: 50px !important;
  padding: 0;
  min-width: 880px;
  overflow: hidden;
}
.container{
  height: 100%;
}
.el-menu{
  border: none;
}
/*vm兼容处理使用Viewport Units Buggyfill造成的副作用，需要如下设置img*/
img {
  content: normal !important;
}
</style>
