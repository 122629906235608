import Vue from 'vue'
import '@/theme/index.css'
import App from './App.vue'
import router from './router'
import echarts from 'echarts'
import china from 'echarts/map/json/china.json'
echarts.registerMap('china', china)
import ElementUI from 'element-ui' //新添加
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI) 
import dataService from '@/service/dataService/dataService'

import {
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Message,
  Loading,
  Button,
  Select,
  Option,
  Table,
  TableColumn
} from 'element-ui';

Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Loading.directive);
Vue.use(Button);
Vue.use(Select);
Vue.use(Option);
Vue.use(Table);
Vue.use(TableColumn);
import instance from "./utils/axios.js";

// 全局配置axios，并使用ajax作为别名

Vue.prototype.ajax = instance

Vue.config.productionTip = false

Vue.prototype.$echarts = echarts


Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;
Vue.prototype.$dataService = dataService;


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
